import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAllClients(callback){

    axios.get(myConfig.api_url+`/api/v1/allClients?api_token=${token}&center=${localStorage.getItem('centre')}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });
    },

    getAllResteApayer(callback){

        axios.get(myConfig.api_url+`/api/v1/allResteApayer?api_token=${token}&center=${localStorage.getItem('centre')}`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
        },

    getClientById(args,callback){

        axios.get(myConfig.api_url+`/api/v1/getClientById?api_token=${token}&id=${args.id}`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    addClient(args,callback){
        const AformData = new FormData();
        if(args.avatar!=null)
            AformData.append("avatar", args.avatar);
        AformData.append("nom", args.nom);
        AformData.append("prenom", args.prenom);
        AformData.append("categorie_idcategorie", args.categorie_idcategorie.toString());
        AformData.append("parent_Id", args.parent_Id);
        AformData.append("CIN", args.CIN);
        AformData.append("telephone", args.telephone);
        AformData.append("email", args.email);
        AformData.append("ecole", args.ecole);
        AformData.append("tuteur", args.tuteur);
        AformData.append("emailTuteur", args.emailTuteur);
        AformData.append("telephoneTuteur", args.telephoneTuteur);
        AformData.append("profession", args.profession);
        AformData.append("idniveau_scolaire", args.idniveau_scolaire?args.idniveau_scolaire.toString():'');
        AformData.append("first_Time", args.first_Time.toString());
        AformData.append("date_naissance", args.date_naissance);
        AformData.append("centre", args.centre);
        AformData.append("idutilisateur", args.idutilisateur.toString());


        axios.post(myConfig.api_url+`/api/v1/addClient?api_token=${token}`,
            AformData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                console.log(response)
                callback(null);
            }
        })
        .catch((e) => {
            console.log(e)
            callback(null);
        });

    },

    updateClient(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateClient?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    createNote(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createNote?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getUserNotes(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getUserNotes?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteNote(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteNote?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateNote(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateNote?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteClient(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteClient?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });
    },

}