<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Seances</h4>
                  <br>
                  <h5 class="box-title text-danger">Total des heures : {{nbr_heures}}</h5>
                  <br>
                  <h5 class="box-title text-danger">Total des réglement : {{total_reglemet}}</h5>
                  <br>
                  <h5 class="box-title text-danger">Reste à régler : {{rester_a_regler}}</h5>
                  <br>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <label class="form-label">Filter:</label>
                  <div style="border:1px dashed black;padding:10px;margin: 10 20px">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Date min</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="filter.datemin"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Date Max</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="filter.datemax"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row" style="align-items:center">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Prof</label>
                          <select class="form-select" v-model="filter.prof">
                            <option :value="null">Non spécifié </option> 
                            <option
                            :value="m['idprof']"
                              v-for="m in Profs"
                              :key="m['idprof']"
                              >{{m.nom + ' ' + m.prenom}}</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group" style="margin:0">
                          
                          <label class="form-label"  style="margin:0">
                            <input
                              type="checkbox"
                              style="margin: 0px;position: unset;opacity: 1;"
                              v-model="filter.regler"
                            />
                            Séances en attente de réglememt
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style="text-align: end;">
                      <button @click="filtrer" class="waves-effect  btn mb-5 " style="background-color:rgb(13, 201, 13);color:white;margin:0 5px">
                        Filtrer
                      </button>
                      <button @click="reinitialiser" class="waves-effect btn mb-5 " style="background-color:red;color:white;margin:0 5px">
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                  <div style="display:flex;">
                    <span style="margin:0 5px;display:flex;align-items:center">
                      <input @change="selectR" style="position: unset;opacity: 1;width: 30px;height: 30px;margin:3px" type="checkbox">
                      Selectionner Tous à régler
                    </span>
                    <span style="margin:0 5px;display:flex;align-items:center">
                      <input @change="selectP" style="position: unset;opacity: 1;width: 30px;height: 30px;margin:3px" type="checkbox">
                      Selectionner Tous à imprimer la fiche
                    </span>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="seances"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Prof</th>
                            <th>Groupe</th> 
                            <th>Date</th> 
                            <th>Horaire</th> 
                            <th>Nbr heures</th> 
                            <th>Tarif horaire</th>
                            <th>Montant</th>
                            <th>Réglement</th>
                            <th>
                              <button @click="ReglerMultiple" class="waves-effect  btn mb-5 " style="background-color:#00baff;color:white;margin:0 5px">
                                Régler
                              </button>
                            </th>
                            <th>
                              <button @click="FicheModal" class="waves-effect  btn mb-5 " style="background-color:#00baff;color:white;margin:0 3px;padding:2px">
                                Fiche de paiement
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Regler seance -->
        <div
          class="modal fade"
          id="ReglerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Régler</h5>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment Régler cette seance?</h4>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeReglerModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addseance" @click="Regler">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier seance -->

        <!-- modal Supprimer seance -->
        <div
          class="modal fade"
          id="AnnulerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Annuler Réglement</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeAnnulerModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment Annuler le Réglement de cette seance?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAnnulerModal">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteseance" @click="Annuler">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer seance -->

        <!-- modal imprimer fiche de paiement -->
        <div
          class="modal fade"
          id="FicheModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Imprimer Fiche de paiement</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeFicheModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeFicheModal">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deleteseance" @click="ImprimerMultiple">
                  Imprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal imprimer fiche de paiement -->
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import ProfApi from "../../../api/prof";
import CaisseApi from "../../../api/caisse";
import moment from 'moment';

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
  },
  data() {
    return { 
      loader:false,
      Seances:[],  
      montant:0,
      formU:{
        id:'',
        montant:'',
        prof:'',
      },
      formD:{ 
        id:'',
        montant:'',
        prof:'',
      },
      Profs:[],
      SelectedArray:[],
      SelectedArrayR:[],
      url:null,
      filter:{
        datemin:null,
        datemax:null,
        prof:null,
        regler:false,
      },
      rester_a_regler:null,
      total_reglemet:null,
      nbr_heures:null,
    };
  },
  mounted() {
    this.datatable();
    ProfApi.getAll((r) => {
      if(r!=null){
        this.Profs = []; 
        for(const p of r.data){
          if(p.centre_idcentre == localStorage.getItem('centre'))
            this.Profs.push(p)
        }
      }
    });
  },
  methods: {
    async loadData() {
      this.SelectedArrayR = [];
      this.SelectedArray = [];
      this.loader = true;
      await ProfApi.getAllSeances((r) => {
        if(r!=null){
          if(r.success==true){
            this.Seances = []; 
            this.nbr_heures= 0;
            this.total_reglemet= 0;
            this.rester_a_regler= 0;
            for (const s of r.data ) {
              if(s.centre_idcentre == localStorage.getItem('centre')){
                if(this.filter.regler==true){
                  if((moment(this.filter.datemin) <= moment(s.date) || this.filter.datemin == null)
                  && (moment(this.filter.datemax) >= moment(s.date) || this.filter.datemax == null) 
                  && (this.filter.prof == s.idprof || this.filter.prof == null) 
                  && s.reglement == null)
                  {
                    this.nbr_heures=this.nbr_heures+parseFloat(s.nbr_heurs);
                    if(s.reglement!=null)
                      this.total_reglemet=this.total_reglemet+parseInt(s.montant);
                    else
                      this.rester_a_regler=this.rester_a_regler+parseInt(s.montant);
                    this.Seances.push({
                      id:s.idseance,
                      idprof:s.idprof,
                      Prof:s.Prof,
                      groupe:s.groupe,
                      date:s.date,
                      horaire:s.horaire,
                      nbr_heurs: parseFloat(s.nbr_heurs).toFixed(1),
                      tarif:s.tarif,
                      montant:s.montant,
                      reglement:s.reglement,
                    })
                  }
                }
                else{
                  if((moment(this.filter.datemin) <= moment(s.date) || this.filter.datemin == null)
                  && (moment(this.filter.datemax) >= moment(s.date) || this.filter.datemax == null) 
                  && (this.filter.prof == s.idprof || this.filter.prof == null))
                  {
                    this.nbr_heures=this.nbr_heures+parseFloat(s.nbr_heurs);
                    if(s.reglement!=null)
                      this.total_reglemet=this.total_reglemet+parseInt(s.montant);
                    else
                      this.rester_a_regler=this.rester_a_regler+parseInt(s.montant);
                    this.Seances.push({
                      id:s.idseance,
                      idprof:s.idprof,
                      Prof:s.Prof,
                      groupe:s.groupe,
                      date:s.date,
                      horaire:s.horaire,
                      nbr_heurs: parseFloat(s.nbr_heurs).toFixed(1),
                      tarif:s.tarif,
                      montant:s.montant,
                      reglement:s.reglement,
                    })
                  }
                }
              }
            }   
            var t = $("#seances").DataTable();
            t.clear().rows.add(this.Seances).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    filtrer(){
      this.SelectedArrayR = [];
      this.SelectedArray = [];
      this.loadData();
    },
    reinitialiser(){
      this.SelectedArrayR = [];
      this.SelectedArray = [];
      this.filter.datemin = null;
      this.filter.datemax = null;
      this.filter.prof = null;
      this.filter.regler = false;
      this.loadData();
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#seances").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Seances,
          columns: [
            { data: "id" },
            { data: "Prof" },
            { data: "groupe" }, 
            { data: "date" }, 
            { data: "horaire" }, 
            { data: "nbr_heurs" }, 
            { data: "tarif" }, 
            { data: "montant" }, 
            { data: "reglement" }, 
            {data:"reglement",
              render:function(data){
                if(data==null)
                  return `<input class="RegelementCheck" style="position: unset;opacity: 1;width: 30px;height: 30px;" type="checkbox">`;
                else return "";
              }
            },
            {data:"reglement",
              render:function(data){
                if(data!=null)
                  return `<input class="PaiementCheck" style="position: unset;opacity: 1;width: 30px;height: 30px;" type="checkbox">`;
                else return "";
              }
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnRegler"> <i title="Régler" style="cursor:pointer;" class="ti-check  " aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnAnnuler"><i title="annuler Réglement" style="cursor:pointer;" class="ti-close " aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this;

      $("#seances tbody ").on("change", ".RegelementCheck", function () {
       
        var t = $("#seances").DataTable();
        var data = t.row($(this).parents("tr")).data();
        const id = data["id"].toString();
        if(thisVue.SelectedArrayR.map(v=>v.id.toString()).indexOf(id)==-1){
          thisVue.SelectedArrayR.push(data);
        }
        else{
          thisVue.SelectedArrayR.splice(thisVue.SelectedArrayR.map(v=>v.id.toString()).indexOf(id),1);
        }
      });

      $("#seances tbody ").on("change", ".PaiementCheck", function () {
       
        var t = $("#seances").DataTable();
        var data = t.row($(this).parents("tr")).data();
        const id = data["id"].toString();
        if(thisVue.SelectedArray.map(v=>v.id.toString()).indexOf(id)==-1){
          thisVue.SelectedArray.push(data);
        }
        else{
          thisVue.SelectedArray.splice(thisVue.SelectedArray.map(v=>v.id.toString()).indexOf(id),1);
        }
      });
    
      $("#seances tbody ").on("click", ".btnRegler", function () {
       
        var t = $("#seances").DataTable();
        var data = t.row($(this).parents("tr")).data();
        if(data["reglement"]==null){
          thisVue.ReglerModal()
          thisVue.formU.id = data["id"];
          thisVue.formU.montant = data["montant"];
          thisVue.formU.prof = data["Prof"];
        }
      });

     
      $("#seances tbody ").on("click", ".btnAnnuler", function () {
        var t = $("#seances").DataTable();
        var data = t.row($(this).parents("tr")).data();
        if(data["reglement"]!=null){
          thisVue.AnnulerModal()
          thisVue.formD.id = data["id"];
          thisVue.formD.montant = data["montant"];
          thisVue.formD.prof = data["Prof"];
        }
      });
    },
    ReglerModal() {
      $("#ReglerModal").modal("show");
    },
    closeReglerModal() {
      $("#ReglerModal").modal("hide");
    },
    AnnulerModal() {
      $("#AnnulerModal").modal("show");
    },
    closeAnnulerModal() {
      $("#AnnulerModal").modal("hide");
    },
    ClearModal(){},
    selectR(e){
      if(e.target.checked){
        this.SelectAll();
      }
      else{
        this.UnSelectAll();
      }
    },
    selectP(e){
      if(e.target.checked){
        this.SelectAllP();
      }
      else{
        this.UnSelectAllP();
      }
    },
    SelectAll(){
      for(const d of document.getElementsByClassName('RegelementCheck')){
        d.checked = true;
      }
      for(const s of this.Seances){
        if(s.reglement==null){
          this.SelectedArrayR.push(s);
        }
      }
    },
    UnSelectAll(){
      for(const d of document.getElementsByClassName('RegelementCheck')){
        d.checked = false;
      }
      for(const s of this.Seances){
        if(s.reglement==null){
          if(this.SelectedArrayR.map(v=>v.id.toString()).indexOf(s.id)!=-1){
            this.SelectedArrayR.splice(this.SelectedArrayR.map(v=>v.id.toString()).indexOf(s.id),1);
          }
        }
      }
    },
    SelectAllP(){
      for(const d of document.getElementsByClassName('PaiementCheck')){
        d.checked = true;
      }
      for(const s of this.Seances){
        if(s.reglement!=null){
          this.SelectedArray.push(s);
        }
      }
    },
    UnSelectAllP(){
      for(const d of document.getElementsByClassName('PaiementCheck')){
        d.checked = false;
      }
      for(const s of this.Seances){
        if(s.reglement!=null){
          if(this.SelectedArray.map(v=>v.id.toString()).indexOf(s.id)!=-1){
            this.SelectedArray.splice(this.SelectedArray.map(v=>v.id.toString()).indexOf(s.id),1);
          }
        }
      }
    },
    async ReglerMultiple(){
      if(this.filter.prof!=null){
        let prof = "";
        let montant = 0;
        const monthNamesFr = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        let currentMonth = monthNamesFr[new Date().getMonth()];
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        for(const s of this.SelectedArrayR){
          if(s.idprof == this.filter.prof){
            montant+= parseFloat(s.montant);
            prof = s.Prof;
            currentMonth = monthNamesFr[new Date(s.date).getMonth()];
          }
        }
        if(this.SelectedArrayR.length==0){
          $("#globalLoader").removeClass("S");
          $("#globalLoader").addClass("H");
        }
        else{
          for(const s of this.SelectedArrayR){
            if(s.idprof == this.filter.prof){
              ProfApi.ReglerSeance({
                id:s.id,
                date:moment().format('YYYY-MM-DD')
              },async(r) => {
                if(r!=null)
                {
                  if(r.success!=true){
                    console.log("error",r.msg)
                  }
                }
              });
            }
          }
          await CaisseApi.add({
            recette:false,   
            libelle:"Reglement de Prof : "+prof+", mois " + currentMonth,   
            montant:montant,   
            utilisateur_idutilisateur:localStorage.getItem("id"),   
            centre:localStorage.getItem("centre"),
          },(rr) => {
            if(rr!=null)
            {
              if(rr.success==true){
                $("#globalLoader").removeClass("S");
                $("#globalLoader").addClass("H");
                this.loadData();
                this.ClearModal();
                this.closeReglerModal()
              }
            }
          });
        }

      }
      else{
        alert('Aucune prof a été sélectionné!');
      }
    },
    FicheModal() {
      if(this.filter.prof!=null){
        const list = [];
        for(const s of this.SelectedArray){
          if(s.idprof == this.filter.prof){
            list.push(s);
          }
        }
        if(list.length!=0){
          this.montant = 0;
          $("#FicheModal").modal("show");
        }
      }
      else{
        alert('Aucune prof a été sélectionné!');
      }
    },
    closeFicheModal() {
      $("#FicheModal").modal("hide");
    },
    ImprimerMultiple(){
      if(this.filter.prof!=null){
        const list = [];
        const mois = [];
        let total = 0;
        let prof = "";
        for(const s of this.SelectedArray){
          if(s.idprof == this.filter.prof){
            list.push(s);
            prof = s.Prof;
            total += parseFloat(s.montant);
            if(mois.indexOf(moment(s.date).month())==-1)
              mois.push(moment(s.date).month());
          }
        }
        if(list.length!=0){
          const _list = JSON.stringify(list);
          const _mois = JSON.stringify(mois);
          const data = {
            liste:_list,
            mois:_mois,
            Prof:prof,
            total:total,
            forfeit:this.montant
          };
          this.closeFicheModal();
          this.$router.push({ name: "Fiche" , params: data });
        }
      }
      else{
        alert('Aucune prof a été sélectionné!');
      }
    },
    Regler(){ 
      ProfApi.ReglerSeance({
        id:this.formU.id,
        date:moment().format('YYYY-MM-DD')
       },async(r) => {
         if(r!=null)
         {
           if(r.success==true){
            await CaisseApi.add({
              recette:false,   
              libelle:"Reglement de Prof : "+this.formU.prof+", seance N°" + this.formU.id,   
              montant:this.formU.montant,   
              utilisateur_idutilisateur:localStorage.getItem("id"),   
              centre:localStorage.getItem("centre"),
            },(rr) => {
              if(rr!=null)
              {
                if(rr.success==true){
                  this.loadData();
                  this.ClearModal();
                  this.closeReglerModal();
                }
              }
            });
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    
    },
    Annuler(){
      ProfApi.AnnulerReglementSeance({
        id:this.formD.id,   
       },async(r) => {
         console.log(r)
         if(r!=null)
         {
           if(r.success==true){
             await CaisseApi.add({
              recette:true,   
              libelle:"Annuler Reglement de Prof : "+this.formD.prof+", seance N°" + this.formD.id,   
              montant:this.formD.montant,   
              utilisateur_idutilisateur:localStorage.getItem("id"),
              centre:localStorage.getItem("centre"),   
            },(rr) => {
              if(rr!=null)
              {
                if(rr.success==true){
                  this.loadData();
                  this.ClearModal();
                  this.closeAnnulerModal()
                }
              }
            });
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
