<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Inscriptions</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15"> 
                  <label class="form-label">Filter:</label>
                  <div style="border:1px dashed black;padding:10px;margin: 10 20px">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Groupe</label>
                          <select class="form-select" v-model="filter.groupe">
                            <option :value="null">Non spécifié </option> 
                            <option :value="-1">Non affecté </option> 
                            <option
                            :value="m['idgroupe']"
                              v-for="m in Groupes"
                              :key="m['idgroupe']"
                              >{{"Gr N" + m.idgroupe + " , " + m.niveau}}</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Matière</label>
                          <select class="form-select" v-model="filter.mat">
                            <option :value="null">Non spécifié </option> 
                            <option
                            :value="m['idmatiere']"
                              v-for="m in Matieres"
                              :key="m['idmatiere']"
                              >{{m.designation}}</option> 
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="align-items:center">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Categorie</label>
                          <select class="form-select" v-model="filter.cat">
                            <option :value="null">Non spécifié </option> 
                            <option
                            :value="m['idcategorie']"
                              v-for="m in Categories"
                              :key="m['idcategorie']"
                              >{{m.designation}}</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Convention</label>
                          <select class="form-select" v-model="filter.convention">
                            <option :value="null">Non spécifié </option> 
                            <option
                            :value="m['idconvention']"
                              v-for="m in Conventions"
                              :key="m['idconvention']"
                              >{{m.organisme}}</option> 
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Duplication</label>
                          <select class="form-select" v-model="filter.dup">
                            <option :value="null">Non spécifié </option> 
                            <option value="OUI">OUI</option> 
                            <option value="NON">NON</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Periode</label>
                          <Multiselect
                            v-model="filter.periode"
                            :options="Periodes"
                            valueProp="idperiode"
                            label="designation"
                            trackBy="designation"
                            placeholder="Choisie Periodes"
                            mode="tags"
                          />
                        </div>
                      </div>
                    </div>
                    <div style="text-align: end;">
                      <button @click="filtrer" class="waves-effect  btn mb-5 " style="background-color:rgb(13, 201, 13);color:white;margin:0 5px">
                        Filtrer
                      </button>
                      <button @click="reinitialiser" class="waves-effect btn mb-5 " style="background-color:red;color:white;margin:0 5px">
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="inscriptions"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr> 
                            <th>ID</th>
                            <th>date</th>
                            <th>Client</th>
                            <th>Categorie</th>
                            <th>Matiere</th>
                            <th>date de naissance</th>
                            <th>telephone</th>
                            <th>niveau scolaire</th>
                            <th>Groupe</th>
                            <th>Duplication</th>
                            <th>Horaire</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Horaires prof -->
        <div
          class="modal fade"
          id="showHorairesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Horaires</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowHoraires"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeHoraires.length==0"><td colspan="1" align="center">Aucun Horaire trouve</td></tr>
                        <tr v-for="(m,i) of GroupeHoraires" :key="i">
                          <td>{{weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']}}</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowHoraires">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Horaires -->
        <!-- modal Modifier groupe -->
        <div
          class="modal fade"
          id="UpdateGroupeModal1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifier groupe : </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Matiere</label>
                              <div :class="v.updatedGroupe.updatedMat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select"  @change="loadUpdateGroupes" v-model="updatedMat">
                                  <option :value="null">Choisie</option>
                                  <option
                                    v-for="m of Matieres"
                                  :value="m['idmatiere']"
                                    :key="m['idmatiere']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.updatedGroupe.updatedMat.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadence</label>
                              <div :class="v.updatedGroupe.updatedCad.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select"  @change="loadUpdateGroupes" v-model="updatedCad">
                                  <option :value="null">Choisie</option>
                                  <option
                                    v-for="m of Cadences"
                                  :value="m['idcadence-hebdo']"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.updatedGroupe.updatedCad.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Periodes:</label>
                                
                                <Multiselect
                                  v-model="updatedPeriodes"
                                  :options="Periodes"
                                  valueProp="idperiode"
                                  label="designation"
                                  trackBy="designation"
                                  placeholder="Choisie Periodes"
                                  mode="tags"
                                  @change="ChangeUPeriodes"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Date debut:</label>
                                <div class="input-group mb-15">
                                  <input
                                    type="date"
                                    class="form-control"
                                    v-model="UDebutDate"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Montant:</label>
                                <div :class="v.updatedGroupe.updatedMontant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="updatedMontant"
                                  />
                                </div>
                                <div class="input-errors" v-for="error of v.updatedGroupe.updatedMontant.$errors" :key="error.$uid">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div> 
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Groupe</label>
                              <div class="input-group mb-15">
                                <select class="form-select"  @change="selectGroupeHoraire2" v-model="updatedGroupe">
                                  <option :value="null">Non affecté</option>
                                  <option
                                    v-for="m of UGroupes"
                                  :value="m['idgroupe']"
                                    :key="m['idgroupe']"
                                    >{{m.niveau}}</option> 
                                </select>
                              </div>
                            </div>
                          </div>
                          <h4 >Horaires du groupe</h4> 
                          <div class="table-responsive">
                            <div
                              id="example_wrapper"
                              class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                            >
                              <table
                                id="Reste"
                                class="table table-bordered table-striped dataTable"
                                data-page-size="10"
                              >
                                <thead>
                                  <tr>
                                    <th>jour</th>
                                    <th>Horaire</th> 
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(g,i) of GroupeHoraires2" :key="i">
                                    <td>{{weekDays[g.jour]}}</td>
                                    <td>{{"à "+g['heure-debut']+":"+g['min-debut']+" de "+g['heure-fin']+":"+g['min-fin']}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeUpdateGroupeModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="updateGroupe">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier groupe -->
        <div v-if="openFac">
          <Facture :FI="formI" />
        </div>
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import InscriptionApi from "../../../api/inscription";
import inscriptionApi from "../../../api/inscription";
import groupeApi from "../../../api/groupe"; 
import catApi from "../../../api/categorie"; 
import matApi from "../../../api/matiere"; 
import cadenceApi from "../../../api/cadence"; 
import conventionApi from "../../../api/convention"; 
import anneeApi from "../../../api/annees"; 
import Multiselect from '@vueform/multiselect';
import moment from "moment";
import Facture from "../../../components/facture.vue";

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
    Multiselect,
    Facture,
  },
  data() {
    return { 
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      Inscriptions:[], 
      GroupeHoraires:[],   
      Groupes:[], 
      Matieres:[], 
      Categories:[], 
      selectedId:null,
      url:null,
      filter:{
        groupe:null,
        mat:null,
        cat:null,
        dup:null,
        convention:null,
        periode:[],
      },
      updatedGroupe:null,
      updatedMat:null,
      updatedCad:null,
      updatedCon:null,
      UDebutDate:null,
      updatedMontant:null,
      updatedPeriodes:[],
      GroupeHoraires2:[],
      UGroupes:[], 
      Periodes:[],
      Conventions:[],  
      Cadences:[],  
      SelectedIdInscription:null,
      SelectedInscription:{},
      formI:{ 
        nom:'',
        prenom:'', 
        idclient:'' ,
        idfacture:0,
        CategorieId:null,
      },
      openFac:false,
    };
  },
  validations () {
    return { 
      updatedGroupe:{ 
        updatedMat:{ required },
        updatedCad:{ required },
        updatedCon:{ required },
        updatedMontant:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    groupeApi.getAll((r) => {
      if(r!=null){
        if(r.success==true){ 
          console.log(r.data)
          for(const g of r.data){
            if(g.centre_idcentre == localStorage.getItem('centre')){
              this.Groupes.push(g);
            }
          }
        }
      }
    });
    cadenceApi.getAll((r) => {
      this.Cadences=r.data; 
    });
    conventionApi.getAll((r) => {
      if(r){
        this.Conventions=[]; 
        for(const c of r.data){
          if(c.centre == localStorage.getItem('centre') || c.centre == null){
            this.Conventions.push(c);
          }
        }
      }
    });
    matApi.getAll((r) => {
      if(r!=null){
        if(r.success==true){ 
          this.Matieres = r.data;
        }
      }
    });
    catApi.getAllCategories((r) => {
      if(r!=null){
        if(r.success==true){ 
          this.Categories = r.data;
        }
      }
    });

    anneeApi.getAnneePeriodesByIdAnne({id:localStorage.getItem("annee")},(rr)=>{
      this.Periodes = [];
      for(const p of rr.data){
        //if(moment(p.fin) > moment()){
          this.Periodes.push(p)
        //}
      }
    })
       
  },
  methods: {
    checkPeriodes(arr1,arr2){
      let check = false;
      for(const a1 of arr1){
        for(const a2 of arr2){
          if(a1==a2)
            check = true;
        }
      }
      return check;
    },
    async loadData() {
      this.loader = true;
      await InscriptionApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Inscriptions = []; 
            for (const s of r.data ) {
              if(r.data.filter(v=>v.client==s.client).length>1)
                s.check = "OUI";
              else
                s.check = "NON";
              if((this.filter.cat == s.idcategorie || this.filter.cat == null)
                && (this.filter.mat == s.idmatiere || this.filter.mat == null) 
                && (this.filter.dup == s.check || this.filter.dup == null) 
                && (this.filter.groupe == s.idgroupe || (this.filter.groupe == -1 && s.idgroupe == null) || this.filter.groupe == null) 
                && (this.checkPeriodes(s.periodes.map(v=>v.periode_idperiode),this.filter.periode) || this.filter.periode.length == 0)
                && (this.filter.convention == s.idconvention || this.filter.convention == null) 
                )
              {
                this.Inscriptions.push(s)
              }
            }  
            console.log(this.Inscriptions)
            var t = $("#inscriptions").DataTable();
            t.clear().rows.add(this.Inscriptions).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    filtrer(){
      this.loadData();
    },
    reinitialiser(){
      this.filter.groupe = null;
      this.filter.cat = null;
      this.filter.mat = null;
      this.filter.dup = null;
      this.filter.convention = null;
      this.loadData();
    },
    OpenFacture(d){
      console.log(d)
      this.openFac = false;
      setTimeout( ()=> {
        this.formI.nom = d.nom;
        this.formI.prenom = d.prenom;
        this.formI.idclient = d.idclient;
        this.formI.idfacture = d.idfacture;
        this.formI.CategorieId = d.idcategorie;
        this.openFac = true;
      },500)
    },
    datatable() {
      this.loadData();
      const thisVue = this; 
      $(function () {
        "use strict";
        $("#inscriptions").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          order:[[0,"desc"]],
          data: this.Inscriptions,
          columns: [ 
            {data: "idinscription"},
            {data: "date"},
            {data:"client"},
            {data:"categorie"},
            {data:"matiere"},
            {data:"date-naissance"},
            {data:"telephone"},
            {data:"niveau"},
            {data:"groupe",
              render:function(data){
                if(data==null)
                  return '<span style="color:red">Non affecté</span>';
                else
                  return data;
              }
            },
            {data:"check"},
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowHoraires"> Horaire </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnShow"> <i title="Afficher" style="cursor:pointer;" class="ti-eye  " aria-hidden="true"></i></button><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnUpdate"> <i title="Modifier" style="cursor:pointer;" class="ti-pencil " aria-hidden="true"></i></button>',
            },
          ],
        });
      });

      //for show
      $("#inscriptions tbody ").on("click", ".btnShow", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.OpenFacture(data);
      });

      //for update
      $("#inscriptions tbody ").on("click", ".btnUpdate", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.UpdateGroupeModal(data);
      });
      //for show Horaires
      $("#inscriptions tbody ").on("click", ".btnShowHoraires", function () {
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showHorairesModal(data["idgroupe"]);
      });
      //for delete
      $("#inscriptions tbody ").on("click", ".btnDelete", function () {
        console.log("delete");
        var t = $("#inscriptions").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idinscription = data["idinscription"];
      });
    },   
    ///////////////////////////////////////////////////
    async loadHorairesData() {
      this.GroupeHoraires = []; 
      await groupeApi.getGroupeHorairesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeHoraires = r.data; 
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showHorairesModal(id) {
      this.selectedId = id;
      console.log(this.selectedId)
      $("#showHorairesModal").modal("show");
      this.loadHorairesData();
    },
    closeModalshowHoraires() {
      $("#showHorairesModal").modal("hide");
    },
    ///////////////////////////////////////////////////
    ClearModal(){},  
    ChangeUPeriodes(v){
      this.updatedPeriodes = v;
      this.UdebutFInDate();
      this.loadUpdateGroupes();
    },
    UdebutFInDate(){
      const debutDates = [];
      const finDates = [];
      let debut = "";
      const periodes = [];
      for(const p of this.Periodes){
        if(this.updatedPeriodes.map(v=>v.toString()).indexOf(p.idperiode.toString())!=-1){
          periodes.push(p);
        }
      }
      for(const a of periodes){
        debutDates.push(a.debut);
      }
      for(let i = 0 ; i < debutDates.length ; i++){
        if(i==0){
          debut = debutDates[i];
        }
        else if(moment(debutDates[i])<moment(debut)){
          debut = debutDates[i];
        }
      }
      if(moment()>moment(debut))
        this.UDebutDate = moment().format("YYYY-MM-DD");
      else
        this.UDebutDate = debut;
      this.updatedGroupe = null;
    },
    UpdateGroupeModal(insc) {
      console.log(insc)
      this.updatedGroupe = null;
      this.updatedMat = null;
      this.updatedCad = null;
      this.updatedCon = null;
      this.UDebutDate = null;
      this.updatedPeriodes = [];
      this.SelectedInscription = insc;
      this.updatedGroupe = insc.idgroupe;
      this.updatedMat = insc.idmatiere;
      this.updatedCad = insc.idcadence;
      this.updatedCon = insc.idconvention;
      this.UDebutDate = insc.date;
      this.updatedMontant = insc.montant;
      this.UGroupes=[];
      this.GroupeHoraires2 = [];
      inscriptionApi.getAllPeriodesByInscription({id:this.SelectedInscription.idinscription},async(r)=>{
        if(r){
          console.log(r)
          this.updatedPeriodes = [];
          for(const a of r.data){
            this.updatedPeriodes.push(a.periode_idperiode);
          }
        }
      })
      this.loadUpdateGroupes();
      $("#UpdateGroupeModal1").modal("show");
    },
    loadUpdateGroupes() {
      $("#globalLoader").removeClass("H");
      $("#globalLoader").addClass("S");
      console.log({
          idmatiere:this.updatedMat,
          idcadence:this.updatedCad,
          idcategorie:this.SelectedInscription.idcategorie,
          idannee:localStorage.getItem("annee"),
        })
      groupeApi.getGroupesByMatCadAnn(
        {
          idmatiere:this.updatedMat,
          idcadence:this.updatedCad,
          idcategorie:this.SelectedInscription.idcategorie,
          idannee:localStorage.getItem("annee"),
        },
        async (r) => {
          console.log("groupes",r.data)
          if(r.data.length==0){
            $("#globalLoader").removeClass("S");
            $("#globalLoader").addClass("H");
            this.selectGroupeHoraire2();
          }
        this.UGroupes=[]; 
        for(const g of r.data){
          if(g.centre_idcentre == localStorage.getItem('centre')){
            await groupeApi.getGroupePeriodesByIdGroupe({id:g.idgroupe},async(rr)=>{
              if(rr){
                await inscriptionApi.getAllPeriodesByInscription({id:this.SelectedInscription.idinscription},async(rrr)=>{
                  if(rrr){
                    let verify = true;
                    for(const a of rrr.data){
                      if(rr.data.map(p=>p.idperiode.toString()).indexOf(a.periode_idperiode.toString())==-1){
                        verify = false;
                      }
                    }
                    if(verify){
                      this.UGroupes.push(g);
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                      this.selectGroupeHoraire2();
                    }
                  }
                })
              }
            })
          }
        }
      });
    },
    closeUpdateGroupeModal() {
      $("#UpdateGroupeModal1").modal("hide");
    },
    selectGroupeHoraire2(){
      this.GroupeHoraires2 = [];
      if(this.updatedGroupe!=null){
        groupeApi.getGroupeHorairesByIdGroupe(
          {
            id:this.updatedGroupe,
          },
          (r) => {
          this.GroupeHoraires2=r.data; 
        });
      }
    },
    updateGroupe(){
      if(this.updatedMat != null && this.updatedCad != null && this.UDebutDate != null && this.updatedPeriodes.length != 0){
        inscriptionApi.updateInscriptionGroupe({
          id:this.SelectedInscription.idinscription,
          idgroupe:this.updatedGroupe,
          idmatiere:this.updatedMat,
          idcadence:this.updatedCad,
          date:this.UDebutDate,
          montant:this.updatedMontant,
        },async(r) => {
          console.log(r)
          if(r!=null)
          {
            if(r.success==true){
              for(const p of this.updatedPeriodes){
                await inscriptionApi.addInscriptionPeriode({idinscription:this.SelectedInscription.idinscription,idperiode:p},(rr)=>{
                  if(rr){
                    console.log("periode",rr);
                  }
                })
              }
              this.loadData();
              this.closeUpdateGroupeModal();
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
